import { defineStore } from "pinia";
import { ref } from "vue";
import { useUserStore } from "@/stores/userStore";
import { useConnectivityStore } from "@/stores/connectivity";
import auth from "@/auth";
import router from "@/router";
import { useConfigStore } from "@/stores/config";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";

export const useAppTimeoutStore = defineStore("appTimeouts", () => {
  let timeLeft = ref("");
  let showTimeoutPopup = ref(false);
  const userStore = useUserStore();
  const connectivity = useConnectivityStore();
  const configStore = useConfigStore();

  const sessionTimeout =
    parseInt(
      configStore.getEnvConfigValue(ENV_CONFIG_PROPERTY.SESSION_TIMEOUT)
    ) || 8 * 60 * 60 * 1000; //8 hours
  let sessionTimer;
  let sessionCountdownTimer;
  const idleTimeout =
    parseInt(configStore.getEnvConfigValue(ENV_CONFIG_PROPERTY.IDLE_TIMEOUT)) ||
    60 * 60 * 1000; // 1 hour
  let idleTimer;
  let idleCountdownTimer;
  let goOnlineCountdownTimer;
  //sow action buttons only for online timer
  let showButtons = ref(false);
  let goOfflineCountdownTimer;
  //offline session timer - used to validate offline session
  let sessionTimerEnd;

  const resetIdleTimer = () => {
    clearInterval(idleCountdownTimer);
    clearTimeout(idleTimer);
    showTimeoutPopup.value = false;
    timeLeft.value = "";

    idleTimer = setTimeout(() => {
      showTimeoutPopup.value = true;
      startIdleCountdown();
    }, idleTimeout - 5 * 60 * 1000);
  };

  const startIdleCountdown = () => {
    showButtons.value = false;
    timeLeft.value = "";
    window.addEventListener("click", onUserActivity);
    window.addEventListener("keypress", onUserActivity);
    let remainingTime = 5 * 60; // 5 minutes
    idleCountdownTimer = setInterval(() => {
      remainingTime--;
      timeLeft.value = `Session idle timeout in ${millisToMinutesAndSeconds(
        remainingTime * 1000
      )}.  Press a key or click the mouse to reset your session.`;
      if (remainingTime === 0) {
        showTimeoutPopup.value = false;
        logout();
        clearInterval(idleCountdownTimer);
        clearTimeout(idleTimer);
      }
    }, 1000);
  };

  const onUserActivity = () => {
    // User activity detected, reset the idle timer
    resetIdleTimer();
  };

  //Session timer
  const startSessionTimer = () => {
    sessionTimer = setTimeout(() => {
      startSessionCountdown();
      showTimeoutPopup.value = true;
    }, sessionTimeout - 5 * 60 * 1000);
    if (!connectivity.getisOnline) {
      sessionTimerEnd = new Date().getTime() + sessionTimeout;
    }
  };

  const startSessionCountdown = () => {
    showButtons.value = false;
    timeLeft.value = "";
    //clear idle timers and reset idle timeout listeners when session timeout started
    clearIdleTimers();
    let remainingTime = 5 * 60; // 5 minutes
    sessionCountdownTimer = setInterval(() => {
      remainingTime--;
      timeLeft.value = `Session timeout in ${millisToMinutesAndSeconds(
        remainingTime * 1000
      )}.`;
      if (remainingTime === 0) {
        showTimeoutPopup.value = false;
        logout();
        clearInterval(sessionCountdownTimer);
        clearTimeout(sessionTimer);
      }
    }, 1000);
  };

  const startGoOnlineTimer = () => {
    if (connectivity.getToggledOnline) {
      startGoOnlineCountdown();
      showTimeoutPopup.value = true;
    } else {
      //if user toggled offline - renew the online status
      connectivity.getServiceStatus();
    }
  };

  const startGoOnlineCountdown = () => {
    showButtons.value = true;
    timeLeft.value = "";
    clearInterval(goOfflineCountdownTimer);
    window.removeEventListener("click", onUserActivity);
    window.removeEventListener("keypress", onUserActivity);
    let remainingTime = 5 * 60; // 5 minutes
    goOnlineCountdownTimer = setInterval(() => {
      remainingTime--;
      timeLeft.value = `Online connection established, please save any changes immediately. Session login in ${millisToMinutesAndSeconds(
        remainingTime * 1000
      )}. Would you like to stay offline?`;
      if (remainingTime === 0) {
        showTimeoutPopup.value = false;
        silentLoginOrRedirect();
        clearInterval(goOnlineCountdownTimer);
      }
    }, 1000);
  };

  //Called when internet connection goes off
  const startGoOfflineCountdown = () => {
    showButtons.value = false;
    timeLeft.value = "";
    clearInterval(goOnlineCountdownTimer);
    window.removeEventListener("click", onUserActivity);
    window.removeEventListener("keypress", onUserActivity);
    showTimeoutPopup.value = true;
    let remainingTime = 5 * 60; // 5 minutes
    goOfflineCountdownTimer = setInterval(() => {
      timeLeft.value = `You have lost internet connection, please save any changes. Session Login in ${millisToMinutesAndSeconds(
        remainingTime * 1000
      )}.`;
      remainingTime--;
      if (remainingTime === 0) {
        showTimeoutPopup.value = false;
        logout();
        clearInterval(goOfflineCountdownTimer);
      }
    }, 1000);
  };

  const logout = () => {
    userStore.setUser(null);
    userStore.setIsAuthenticated(false);
    connectivity.getServiceStatus().then(() => {
      if (connectivity.getOnlineServiceStatus) {
        auth.logout();
        router.push({ name: "LogoutPage" });
      } else {
        router.push({ name: "OfflineLogin" });
      }
    });
  };

  const millisToMinutesAndSeconds = (millis) => {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };

  const resetGoOnlineCountdown = () => {
    clearInterval(goOnlineCountdownTimer);
    showTimeoutPopup.value = false;
    //show offline time when the network is online or not toggled offline
    if (connectivity.getOnlineServiceStatus) {
      //set offline timer
      startGoOfflineCountdown();
    }
  };

  const clearSessionTimers = () => {
    clearTimeout(sessionTimer);
    clearInterval(sessionCountdownTimer);
  };

  const clearIdleTimers = () => {
    clearInterval(idleCountdownTimer);
    clearTimeout(idleTimer);
    //events to reset idle timer
    window.removeEventListener("click", onUserActivity);
    window.removeEventListener("keypress", onUserActivity);
  };

  const setOfflineEvent = () => {
    showTimeoutPopup.value = false;
    clearInterval(goOnlineCountdownTimer);
    if (getTimeLeft() < 0) {
      connectivity.toggleOnline(false);
      userStore.setUser(null);
      userStore.setIsAuthenticated(false);
    } else {
      connectivity.toggleOnlineNoRedirect(false);
      connectivity.getServiceStatus();
    }
  };

  const setOnlineEvent = async () => {
    showTimeoutPopup.value = false;
    clearInterval(goOnlineCountdownTimer);
    silentLoginOrRedirect();
  };

  const silentLoginOrRedirect = async () => {
    await connectivity.getServiceStatus();
    if (connectivity.getOnlineServiceStatus) {
      connectivity.toggleOnline(true);
      auth.silentLogin();
    } else {
      logout();
    }
  };

  const getTimeLeft = () => {
    return (sessionTimerEnd || 0) - new Date().getTime();
  };

  const onAppMounted = () => {
    if (userStore.isAuthenticated) {
      // Start the idle timer initially
      resetIdleTimer();
      //start session timer
      startSessionTimer();
      window.addEventListener("online", startGoOnlineTimer);
      window.addEventListener("offline", resetGoOnlineCountdown);
    }
  };

  const onAppUnmounted = () => {
    clearSessionTimers();
    clearIdleTimers();
    clearInterval(goOnlineCountdownTimer);
  };

  return {
    timeLeft,
    showTimeoutPopup,
    showButtons,
    setOfflineEvent,
    setOnlineEvent,
    onAppMounted,
    onAppUnmounted,
    resetIdleTimer,
    clearIdleTimers,
  };
});
